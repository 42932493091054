import request from '../request'

export function share(data) {
    return request({
        url: `/common-service/share/add`,
        method: 'post',
        data,
    })
}

export function fetchData(params) {
    return request({
        url: `/common-service/share/` + params.id,
    })
}

// 获取分享的合同列表
export function fetchContract(data) {
    return request({
        url: '/project-service/contract/share/list',
        method: 'post',
        data,
    })
}