<template>
  <div>
    <a-modal :visible="visible" title="高级搜索" @cancel="cancel" :footer="null">
      <a-form
        :form="form"
        :label-col="{ span: 5 }"
        :wrapper-col="{ span: 16 }"
        :colon="false"
        @submit="handleSubmit"
      >
        <a-form-item label="合同类型">
          <a-select v-decorator="['contractType']">
            <a-select-option
              v-for="item in typeList"
              :key="item.value"
              :value="item.value"
            >{{ item.name }}</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="项目负责人">
          <a-input v-decorator="['charger']" />
        </a-form-item>

        <a-form-item label="工程概况">
          <a-input v-decorator="['scale']" />
        </a-form-item>

        <a-form-item label="阶段">
          <a-select v-decorator="['phase']" mode="multiple">
            <a-select-option
              v-for="item in phaseList"
              :key="item.value"
              :value="item.value"
            >{{ item.name }}</a-select-option>
          </a-select>
        </a-form-item>

        <a-form-item label="项目总投资(万)" style="margin-bottom: 0">
          <a-form-item style="width: 45%; display: inline-block">
            <a-input-number
              :formatter="$inputNumberFormat"
              v-decorator="['investmentMin']"
              style="width: 100%"
            />
          </a-form-item>

          <span style="width: 10%; display: inline-block; text-align: center">-</span>

          <a-form-item style="width: 45%; display: inline-block">
            <a-input-number
              :formatter="$inputNumberFormat"
              v-decorator="['investmentMax']"
              style="width: 100%"
            />
          </a-form-item>
        </a-form-item>

        <a-form-item label="专业">
          <div class="major" v-for="(item, index) in list" :key="item.key">
            <a-cascader
              changeOnSelect
              placeholder
              v-model="item.values"
              :options="majorList"
              :fieldNames="{
                            label: 'name',
                            value: 'value',
                            children: 'children',
                          }"
            />

            <a-button
              size="small"
              type="primary"
              shape="circle"
              icon="plus"
              style="position: absolute; top: 8px; right: -32px"
              @click="add"
              v-if="index === list.length - 1"
            ></a-button>
            <a-button
              size="small"
              type="danger"
              shape="circle"
              icon="minus"
              v-else
              :style="{
                            position: 'absolute',
                            top: '8px',
                            right: index === list.length - 1 ? '-62px' : '-32px',
                          }"
              @click="remove(index)"
            ></a-button>
          </div>
        </a-form-item>

        <div class="right">
          <a-space>
            <a-button @click="cancel">取消</a-button>
            <a-button type="primary" html-type="submit">搜索</a-button>
          </a-space>
        </div>
      </a-form>
    </a-modal>
  </div>
</template>


<script>
import { mapGetters } from "vuex";
export default {
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    searchValues: {
      type: Object,
      default: () => ({})
    }
  },

  data() {
    return {
      form: this.$form.createForm(this),
      list: [
        {
          key: new Date().getTime(),
          values: []
        }
      ] // 用户选中的专业列表
    };
  },

  // watch: {
  //   visible(newValue) {
  //     if (newValue) {
  //       const {
  //         scaleList
  //       } = this.searchValues;

  //       if (!scaleList) {
  //         this.list = [
  //           {
  //             key: new Date().getTime(),
  //             values: []
  //           }
  //         ];
  //       }
  //     }
  //   }
  // },

  computed: {
    ...mapGetters("setting", ["findDataDict"]),

    typeList() {
      return this.findDataDict("contractType");
    },

    majorList() {
      return this.findDataDict("contract_scale");
    },

    phaseList() {
      return this.findDataDict("phase");
    }
  },

  methods: {
    add() {
      this.list.push({
        key: new Date().getTime(),
        values: []
      });
    },
    remove(index) {
      console.log("index", index);
      this.list.splice(index, 1);
    },
    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          console.log("values", values, this.list);
          const arr = [];
          this.list.forEach(item => {
            if (Array.isArray(item.values) && item.values.length > 0) {
              arr.push({
                major: item.values[0],
                majorSub: item.values[1],
                scale: item.values[2]
              });
            }
          });
          this.$emit("search", {
            ...values,
            phase: Array.isArray(values.phase)
              ? values.phase.join(",")
              : undefined,
            investmentMin: values.investmentMin
              ? values.investmentMin * 10000
              : undefined,
            investmentMax: values.investmentMax
              ? values.investmentMax * 10000
              : undefined,
            scaleList: arr
          });
          this.cancel();
        }
      });
    },

    cancel() {
      this.$emit("setVisible");
    }
  }
};
</script>


<style lang="less" scoped>
.major {
  position: relative;
}
</style>