<template>
  <span>
    <a-button @click="onClick" :icon="icon"> </a-button>

    <a-modal :visible="visible" title="分享合同" :footer="null" @cancel="cancel">
      <a-form :form="form" :label-col="{ span: 6 }" :wrapper-col="{ span: 16 }" :colon="false" @submit="handleSubmit"
        v-if="!shareURL">
        <a-form-item label="有效期">
          <a-radio-group v-decorator="[
                        'expireDays',
                        { rules: [{ required: true, message: '请选择！' }] },
                      ]">
            <a-radio :value="1"> 1天 </a-radio>
            <a-radio :value="7"> 7天 </a-radio>
            <a-radio :value="30"> 30天 </a-radio>
            <a-radio :value="-1"> 永久有效 </a-radio>
          </a-radio-group>
        </a-form-item>
        <div class="right">
          <a-space>
            <a-button @click="cancel">取消</a-button>
            <a-button type="primary" html-type="submit" :loading="loading">提交</a-button>
          </a-space>
        </div>
      </a-form>

      <div class="share-url" v-else>
        <a-alert message="分享成功，请复制链接，关闭弹窗后无法操作" type="success" show-icon />
        <div class="url">
          <a :href="shareURL" target="_blank">{{ shareURL }}</a>
          <a-icon class="icon" type="copy" @click="$copy(shareURL)" title="复制" />
        </div>
      </div>
    </a-modal>
  </span>
</template>

<script>
import { share } from "@/api/share";

export default {
  props: {
    icon: {
      type: String,
      default: "share-alt",
    },
    list: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      visible: false,
      form: this.$form.createForm(this),
      loading: false,
      shareURL: "",
    };
  },

  watch: {
    visible(newValue) {
      if (!newValue) {
        this.shareURL = "";
      }
    },
  },

  methods: {
    onClick() {
      if (this.list.length === 0) {
        this.$message.error("请选择需要分享的合同");
        return;
      }
      if (this.list.length >= 100) {
        this.$message.error("分享合同数量不能超过100");
        return;
      }
      this.visible = true;
      console.log(this.$route);
    },

    cancel() {
      this.form.resetFields();
      this.visible = false;
    },

    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          console.log(values);
          this.loading = true;
          share({
            type: "contract",
            ...values,
            body: JSON.stringify(this.list.map((item) => item.id)),
          })
            .then((res) => {
              this.$emit("setList", []);
              console.log("res", res);
              if (res.id) {
                this.shareURL =
                  window.location.origin + "/#/share?id=" + res.id;
              }
            })
            .finally(() => {
              this.loading = false;
            });
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.share-url {
  .url {
    margin-top: 12px;
    display: flex;
    align-items: center;
    background-color: #f9f9f9;
    padding: 12px;

    a {
      margin-right: 12px;
    }

    .icon {
      cursor: pointer;
    }
  }
}
</style>

