import request from '../request'

export function fetchList(data) {
    return request({
        url: '/project-service/contract/temporary/list',
        data,
        method: 'post'
    })
}

export function edit(data) {
    return request({
        url: '/project-service/contract/temporary/update',
        method: 'POST',
        data
    })
}

export function editScaleList(data) {
    return request({
        url: '/project-service/contract/temporary/scale',
        method: 'POST',
        data
    })
}