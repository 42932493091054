<template>
  <div>
    <Pane />

    <Search :visible="searchVisible" @setVisible="searchVisible = false" @search="onSearch2"
      :searchValues="searchValues" />

    <div class="container">
      <div class="list">
        <div class="left">
          <a-input-search placeholder="请输入关键词搜索" v-model="searchValue" @search="onSearch">
            <a-button type="primary" slot="enterButton"> 搜索 </a-button>
          </a-input-search>

          <a-button type="primary" style="
              margin-left: 8px;
              background-color: rgb(255, 140, 24);
              border-color: rgb(255, 140, 24);
            " @click="searchVisible = true">高级搜索</a-button>
        </div>

        <Padding />

        <div class="between">
          <a-checkbox v-model="selected"> 未配置 </a-checkbox>

          <a-button-group>
            <a-tooltip>
              <template slot="title"> 一键分享合同 </template>
              <Share :list="allShareList" icon="paper-clip" />
            </a-tooltip>
            <a-tooltip>
              <template slot="title"> 分享合同 </template>
              <Share :list="shareList" @setList="shareList = []" />
            </a-tooltip>
          </a-button-group>
        </div>
        <Padding />

        <a-table bordered :dataSource="list" :loading="loading" @change="onChange" :pagination="{
                    total,
                    current,
                    pageSize,
                    showTotal: (total) => `共 ${total} 条记录`,
                  }" :row-selection="{
                type: 'radio',
                selectedRowKeys,
                onChange: onSelectChange,
              }">
          <a-table-column title="序号" width="50px">
            <template slot-scope="text, row, index">
              {{ index + 1 }}
            </template>
          </a-table-column>
          <a-table-column title="设计编号" width="140px">
            <template slot-scope="text">
              <span style="color: #1890ff">{{ text.code }}</span>
            </template>
          </a-table-column>
          <a-table-column title="合同名称" data-index="name" />
          <a-table-column title="合同金额(万元)" align="right" width="100px">
            <template slot-scope="text">
              <span style="color: #1890ff">
                <Money :money="text.contractAmount" />
              </span>
            </template>
          </a-table-column>

          <a-table-column title="规模" align="center" width="50px">
            <template slot-scope="text">
              <span v-if="text.scaleConfigured === 1">
                <a-icon type="check" style="color: #1890ff; font-size: 18px" />
              </span>
            </template>
          </a-table-column>

          <a-table-column title="操作" align="center" width="80px">
            <template slot-scope="text">
              <a href="#" @click.prevent="addShare(text)">
                {{ renderShareText(text) }}
              </a>
            </template>
          </a-table-column>
        </a-table>
      </div>

      <div class="contract-name">
        <img src="../../assets/icon-contract.png" alt="" />
        <span>{{ activeObj.name }}</span>
      </div>
      <div class="form" v-if="selectedRowKeys.length > 0">
        <div class="item">
          <div class="label">附件</div>
          <div class="value">
            <div class="link" v-for="item in fileList" :key="item">
              <a target="_blank" download :href="item" v-if="item.indexOf('http') !== -1">{{ uploadFileNameFormatter(item)
                              }}</a>
              <a v-else @click.prevent="clickLink(item)">{{
                              fileNameFormatter(item)
                              }}</a>
            </div>
          </div>
        </div>

        <div class="item">
          <div class="label">工程概况</div>
          <div class="value">
            <a-textarea placeholder="请输入工程概况" v-model="specification" :auto-size="{ minRows: 4 }" />
          </div>
        </div>

        <div class="item">
          <div class="label">项目总投资(万元)</div>
          <div class="value">
            <a-input-number :formatter="$inputNumberFormat" style="width: 100%" placeholder="请输入项目总投资"
              v-model="investment" :min="0" />
          </div>
        </div>

        <div class="item">
          <div class="label">项目负责人</div>

          <div class="value">
            <a-input placeholder="请输入项目负责人" v-model="inputCharger" style="width: 240px"
              @keyup.enter.native="addCharger" />
            <a-button type="primary" shape="circle" icon="plus" size="small" @click="addCharger"
              style="margin-left: 8px"></a-button>
          </div>
        </div>

        <div class="item" v-if="chargerList.length > 0">
          <div class="label"></div>
          <div v-for="(item, index) in chargerList" :key="item">
            <a-button>{{ item }}</a-button>
            <a-button type="danger" shape="circle" icon="delete" @click="removeCharger(index)"
              style="margin-left: 8px; margin-right: 8px" size="small"></a-button>
          </div>
        </div>

        <div class="item">
          <div class="label">合同类型</div>
          <div class="value">
            <a-radio-group v-model="type">
              <a-radio v-for="item in typeFilterList" :key="item.value" :value="item.value">{{ item.name }}</a-radio>
            </a-radio-group>
          </div>
        </div>

        <div class="item">
          <div class="label">阶段</div>
          <div class="value">
            <a-checkbox-group v-model="selectedPhaseList">
              <a-checkbox v-for="item in phaseList" :key="item.id" :value="item.value">{{ item.name }}</a-checkbox>
            </a-checkbox-group>
          </div>
        </div>

        <div class="item">
          <div class="label">专业</div>
          <div class="value">
            <a-button class="btn" v-for="item in majorList" :key="item.value"
              :type="selectedMajor === item.value ? 'primary' : 'default'" @click="selectMajor(item.value)">{{ item.name
                            }}</a-button>
          </div>
        </div>

        <div class="item" v-if="subMajorList.length > 0">
          <div class="label">子专业</div>
          <div class="value">
            <a-button class="btn" v-for="item in subMajorList" :key="item.value"
              :type="selectedSubMajor === item.value ? 'primary' : 'default'" @click="selectSubMajor(item.value)">{{
                            item.name }}</a-button>
          </div>
        </div>

        <div class="item" v-if="sizeList.length > 0">
          <div class="label">规模</div>
          <div class="value">
            <a-button class="btn" v-for="item in sizeList" :key="item.value"
              :type="selectedSize === item.value ? 'primary' : 'default'" @click="selectSize(item.value)">{{ item.name
                            }}</a-button>
          </div>
        </div>

        <div class="item">
          <div class="label"></div>
          <div class="value">
            <a-button @click="add" type="primary"> 确认添加 </a-button>
          </div>
        </div>

        <a-table bordered :dataSource="scaleList" :pagination="false">
          <a-table-column title="专业">
            <template slot-scope="text">
              <span>{{ findMajor(text.major) }}</span>
            </template>
          </a-table-column>
          <a-table-column title="子专业">
            <template slot-scope="text">
              <span>{{ findSubMajor(text.major, text.majorSub) }}</span>
            </template>
          </a-table-column>
          <a-table-column title="规模类型">
            <template slot-scope="text">
              <span>{{ findSize(text.major, text.majorSub, text.scale) }}</span>
            </template>
          </a-table-column>
          <a-table-column title="操作">
            <template slot-scope="text, record, index">
              <a href="#" class="danger" @click.prevent="removeText(index)">删除</a>
            </template>
          </a-table-column>
        </a-table>

        <Padding />
        <div class="center">
          <a-button type="primary" html-type="submit" @click="submit" :loading="saveLoading">提交</a-button>
        </div>
      </div>
      <div class="form" v-else>
        <a-empty description="请选择合同" />
      </div>
    </div>
  </div>
</template>

<script>
import watermark from "@/mixins/watermark";
import { fetchList, edit, editScaleList } from "@/api/contract/scale";
import { fetchDetail,  } from "@/api/contract";
import { mapGetters } from "vuex";
import moment from "moment";
import { encode } from "js-base64";

import Search from "./components/search";
import Share from "./components/share";

export default {
  mixins: [watermark],

  components: {
    Search,
    Share,
  },

  data() {
    return {
      searchValue: "",
      selected: false, // 默认查询所有，勾选上查询未配置

      loading: false,
      current: 1,
      pageSize: 10,
      list: [],
      total: 0,

      selectedRowKeys: [],
      activeObj: {},

      specification: "",
      investment: null,
      inputCharger: "",
      chargerList: [],
      type: "",
      selectedPhaseList: [],

      selectedMajor: "",
      selectedSubMajor: "",
      selectedSize: "",

      scaleList: [],
      saveLoading: false,

      fileList: [],

      searchVisible: false, // 高级搜索弹窗
      searchValues: {},

      shareList: [], // 分享列表
      allShareList: [], // 用户搜索结果全部作为分享项
    };
  },

  watch: {
    selected() {
      this.getList();
      this.searchList();
    },
  },

  computed: {
    charger() {
      return this.chargerList.join();
    },

    ...mapGetters("setting", ["findDataDict"]),

    phaseList() {
      return this.findDataDict("phase");
    },

    typeList() {
      return this.findDataDict("contractType");
    },
    typeFilterList() {
      return this.typeList.filter((item) => item.value !== "compensated");
    },

    majorList() {
      return this.findDataDict("contract_scale");
    },
    subMajorList() {
      const obj = this.majorList.find(
        (item) => item.value === this.selectedMajor
      );
      return obj && Array.isArray(obj.children) ? obj.children : [];
    },
    sizeList() {
      const obj = this.subMajorList.find(
        (item) => item.value === this.selectedSubMajor
      );
      return obj && Array.isArray(obj.children) ? obj.children : [];
    },
  },

  mounted() {
    this.getList();

    // 设置水印
    const list = document.querySelector(".list");
    const form = document.querySelector(".form");
    if (list && form) {
      this.setWatermark(list);
      this.setWatermark(form);
    }
  },

  methods: {
    onSearch2(payload) {
      this.searchValue = "";
      this.searchValues = payload;
      this.current = 1;
      this.scaleConfigured = undefined;
      this.getList();

      this.searchList();
    },

    onSearch() {
      this.current = 1;
      this.searchValues = {};
      this.getList();
      this.searchList();
    },

    getList() {
      this.loading = true;
      fetchList({
        pageNum: this.current,
        pageSize: this.pageSize,
        keyWord: this.searchValue,
        scaleConfigured: this.selected ? 0 : undefined,
        ...this.searchValues,
      })
        .then((res) => {
          this.loading = false;
          if (Array.isArray(res.list)) {
            this.list = res.list.map((item) => {
              return {
                ...item,
                key: item.id,
              };
            });
            this.total = res.totalSize;
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },

    searchList() {
      fetchList({
        pageNum: this.current,
        pageSize: 99,
        keyWord: this.searchValue,
        scaleConfigured: this.selected ? 0 : undefined,
        ...this.searchValues,
      })
        .then((res) => {
          this.loading = false;
          if (Array.isArray(res.list)) {
            this.allShareList = Object.freeze(
              res.list.map((item) => {
                return {
                  ...item,
                  key: item.id,
                };
              })
            );
          }
        })
        .catch(() => {});
    },

    onChange(pagination) {
      this.current = pagination.current;
      this.getList();
    },

    onSelectChange(keys) {
      this.selectedRowKeys = keys;

      const obj = this.list.find((item) => item.key === keys[0]);
      this.activeObj = obj;
      if (obj) {
        if (Array.isArray(obj.contractScaleList)) {
          this.scaleList = obj.contractScaleList;
        } else {
          this.scaleList = [];
        }
        this.specification = obj.scale;
        this.investment = obj.investment / 10000;
        this.chargerList = obj.charger ? obj.charger.split(",") : [];
        this.type = obj.type;

        this.selectedPhaseList = obj.phase ? obj.phase.split(",") : [];

        fetchDetail(keys[0]).then((res) => {
          if (res.attachments) {
            this.fileList = res.attachments.split(",");
          }
        });
      }
    },

    selectMajor(value) {
      this.selectedMajor = value;
    },
    selectSubMajor(value) {
      this.selectedSubMajor = value;
    },
    selectSize(value) {
      this.selectedSize = value;
    },

    add() {
      if (!this.selectedMajor) {
        this.$message.error("请选择专业");
        return;
      }
      this.scaleList.push({
        major: this.selectedMajor,
        majorSub: this.selectedSubMajor,
        scale: this.selectedSize,
      });
      this.selectedMajor = "";
      this.selectedSubMajor = "";
      this.selectedSize = "";
    },

    removeText(index) {
      console.log("index", index);
      this.scaleList.splice(index, 1);
    },

    findMajor(value) {
      const obj = this.majorList.find((item) => item.value === value);
      return obj ? obj.name : "";
    },
    findSubMajor(major, value) {
      let name = "";
      const majorObj = this.majorList.find((item) => item.value === major);
      if (majorObj) {
        if (majorObj && majorObj.children) {
          const subMajorObj = majorObj.children.find(
            (item) => item.value === value
          );
          if (subMajorObj) {
            name = subMajorObj.name;
          }
        }
      }

      return name;
    },
    findSize(major, subMajor, value) {
      let name = "";
      const majorObj = this.majorList.find((item) => item.value === major);
      if (majorObj) {
        if (majorObj && majorObj.children) {
          const subMajorObj = majorObj.children.find(
            (item) => item.value === subMajor
          );
          if (subMajorObj && subMajorObj.children) {
            const sizeObj = subMajorObj.children.find(
              (item) => item.value === value
            );
            if (sizeObj) {
              name = sizeObj.name;
            }
          }
        }
      }

      return name;
    },

    submit() {
      if (!this.specification) {
        this.$message.error("请填写工程概况");
        return;
      }
      if (!this.type) {
        this.$message.error("请选择合同类型");
        return;
      }
      if (this.scaleList.length === 0) {
        this.$message.error("请添加专业");
        return;
      }

      this.saveLoading = true;
      Promise.all([
        edit({
          id: this.activeObj.key,
          scale: this.specification,
          investment: this.investment * 10000,
          charger: this.charger,
          type: this.type,
          phase: this.selectedPhaseList.join(","),
        }),
        editScaleList({
          scaleList: this.scaleList.map((item) => {
            return {
              ...item,
              pid: this.selectedRowKeys[0],
            };
          }),
        }),
      ])
        .then(() => {
          this.getList();
        })
        .finally(() => {
          this.saveLoading = false;
        });
    },

    addCharger() {
      if (!this.inputCharger) {
        this.$message.error("请输入项目负责人");
        return;
      }
      if (this.chargerList.indexOf(this.inputCharger.trim()) > -1) {
        this.$message.error("请勿重复添加");
        this.inputCharger = "";
        return;
      }
      this.chargerList.push(this.inputCharger.trim());
      this.inputCharger = "";
    },

    removeCharger(index) {
      this.chargerList.splice(index, 1);
    },

    // 金慧的文件名称格式化
    fileNameFormatter(path) {
      const arr = path.split("_");
      if (arr.length >= 2) {
        return arr[1];
      } else {
        return path;
      }
    },
    // 自己上传的文件名称格式化
    uploadFileNameFormatter(path) {
      const arr = path.split("_");
      if (arr.length > 0) {
        return arr[arr.length - 1];
      } else {
        return path;
      }
    },

    clickLink(path) {
      const num = path.split("_")[0];
      const time = moment().format("YYYY-MM-DD HH:mm:ss");
      const auth = encode(num + "_" + time);
      const url = `http://218.94.135.30:8025/FileStore/Download.aspx?auth=${auth}`;


      window.open(url)
      const that = this;
      this.$info({
        title: '如果浏览器无法下载，请手动复制下方文件链接',
        content: url,
        okText: '复制',
        onOk() {
          that.$copy(url)
        }
      })

      // const hide = this.$message.loading("下载中...");
      // downloadFile({
      //   fileUrl: url,
      // })
      //   .then((blob) => {
      //     saveAs(blob, this.fileNameFormatter(path));
      //   })
      //   .finally(() => {
      //     hide();
      //   });

      // const newWindow = window.open("_blank");
      // newWindow.location = url;

      // const that = this;
      // navigator.clipboard.writeText(url).then(
      //   function () {
      //     that.$success({
      //       title: "提示",
      //       content: "复制链接成功！",
      //     });
      //   },
      //   function () {
      //     that.$error({
      //       title: "提示",
      //       content: "复制链接失败！",
      //     });
      //   }
      // );

      // window.open(url);
    },

    // 分享
    addShare(text) {
      const index = this.shareList.indexOf(text);
      if (index === -1) {
        this.shareList.push(text);
      } else {
        this.shareList.splice(index, 1);
      }
    },
    renderShareText(text) {
      return this.shareList.find((item) => item === text) ? "已选择" : "分享";
    },
  },
};
</script>


<style lang="less" scoped>
.container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 8px;
  grid-template-rows: 48px 1fr;

  &>div {
    background-color: #fff;
    padding: 12px;
    box-sizing: border-box;
  }

  .list {
    grid-row-start: 1;
    grid-row-end: 3;
  }

  .contract-name {
    font-size: 14px;
    font-weight: bold;
    color: #1890ff;
    display: flex;
    align-items: center;

    img {
      width: 15px;
      margin-right: 8px;
    }
  }
}

.form {
  .item {
    display: flex;
    align-items: baseline;
    margin-bottom: 12px;

    .label {
      width: 10em;

      font-size: 14px;
      color: rgba(0, 0, 0, 0.65);
    }

    .value {
      flex: 1;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
    }

    .btn {
      margin-bottom: 8px;
      margin-right: 8px;
    }
  }
}
</style>